import React from "react";
import { FieldView } from "./FieldView";
import { FieldBlockViewProps } from "./utils/types";

export const FieldBlockView: React.FC<FieldBlockViewProps> = ({
  field,
  general,
  value,
}): JSX.Element => {
  return (
    <>
      {field.map((f) => (
        <div className="mb-4">
          <FieldView
            context={value}
            general={{
              ...general,
              $history: general?.$history?.[(f.options as any).key ?? f.key],
            }}
            field={f}
            value={value?.[(f.options as any).key ?? f.key]}
          ></FieldView>
        </div>
      ))}
    </>
  );
};
