import { FieldLabelProps } from "./Label";
import EditableHeader from "../../../../components/EditableHeader";

export type FieldLabelEditableProps = {
  key: string;
  onChange: (options: FieldLabelProps) => void;
  props: FieldLabelProps;
};

export const LabelEditable: React.FC<FieldLabelEditableProps> = ({
  key,
  onChange,
  props,
}) => {
  return (
    <div className="flex space-x-2">
      <EditableHeader
        key={key}
        value={props.options.label}
        className="mb-3.5"
        onTextChange={(value) => {
          onChange({
            ...props,
            options: { ...props.options, label: value },
          });
        }}
      ></EditableHeader>
      <div className="text-red-500">
        {props.options.required === true && "*"}
      </div>
    </div>
  );
};
