import { IFormContext, FieldInputOptions } from "@slui/types";
import { evalLabel } from "../utils/expressions";

export type FieldLabelProps = {
  key: string;
  context: IFormContext;
  options: FieldInputOptions;
};

export const Label: React.FC<FieldLabelProps> = ({ key, context, options }) => {
  const isRequired = options.required === true;
  const label = evalLabel(options.label ?? "", context);

  return (
    <div className="flex space-x-2 mb-2" key={key}>
      <div dangerouslySetInnerHTML={{ __html: label ?? "" }}></div>
      {isRequired && <div className="text-red-500">*</div>}
    </div>
  );
};
